import React from 'react';

import WebinarPage from '../../components/pages/resources/WebinarPage';

import './index.css';

export default (props) => {
  const { pageContext } = props;

  const { resource } = pageContext;

  return (
    <div className="template webinar-page">
      <WebinarPage webinar={resource} />
    </div>
  );
};
